nav.main {
	padding-bottom: 0 !important; 

	.bar-top {
		max-height: 60px;
		min-height: 60px;
	}
} 


nav.main .side-menu li a {
	min-height: 29px;
	height: 29px;
}



.block {
	margin-top: 20px;
	position: relative;
}  
 
// _layout

.flexing {  
	display: flex;

	.bva {
		height: calc(100% - 108px);
		position: relative;

		section {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

