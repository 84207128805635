/// <reference path = "_variables.scss"

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 0;

    &:focus {
        outline: 0;
    }

    &-default {
        padding: 0 30px;
        background-color: #FFF;
        color: $blue;
        font-family: $heading-font;
        font-size: 25px;
        transition: .1s background-color ease-in-out;

        &:hover {
            background-color: darken(#FFF, 6%);
        }

        &:active {
            box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        }
    }

    &.right {
        float: right;
        margin: 8px 4px 8px;
    }
     &.small { 
        font-size: 18px;         
        padding: 0 10px;
        margin: 4px 4px 4px;
    }
 
     &.toggle{ 
         background-repeat:no-repeat;
         background-position:98% center;
         //&.active{background-image: url("/gfx/datatables/sort_asc.png");}
         // &.inactive{background-image: url("/gfx/datatables/sort_desc.png");}
     } 

     &.toggle::after {
        content: " \e817"; 
        font-family: fontello;
        font-size: 20px;
     }

     &-float {
        float: right;

        @media screen and (max-width: 500px){
            float: none;
    display: block;
    margin-top: 10px;
    width: 80%;
        }
     }
}

.btm-btn { 
    font-size: $button-font-size;
    color: #fff;
    text-transform: uppercase;
    font-size: $heading-font;
        padding: 10px 20px;
}

.voeg-toe {
    color: #fff;
    float: left;
    margin: 10px 0px;
    background: #0055b8;
    @media screen and (min-width: $phone-upper-boundary){
		float: right;
    margin: 5px 5px;
	}

        

    &:hover {
        color: #4e4e4d;
    }
}
