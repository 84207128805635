﻿/// <reference path = "_variables.scss"

.article {
	h1, .h1, h2, .h2, h3, .h3 {
		margin-top:20px;margin-bottom:10px;
		&:first-child {margin-top:0;}
	}

	h4, .h4, h5, .h5, h6, .h6 {
		margin-top:10px;margin-bottom:10px;
		&:first-child {margin-top:0;} 
	} 

	h1, .h1 {font-size:40px;}
	h2, .h2 {font-size:36px;}
	h3, .h3 {font-size:30px;}
	h4, .h4 {font-size:24px;}
	h5, .h5 {font-size:20px;}
	h6, .h6 {font-size:17px;font-family:$primary-font;font-weight:bold;}

	p { margin-bottom:10px; font-size:17px;line-height:26px;
		&:last-child {margin-bottom:0;}
	}

	ol, ul {margin-bottom:10px;}

	ol {
		li {}
	}

	ul {padding-left:40px;list-style:disc outside;
		li {list-style:disc outside;}
	}
}