/// <reference path = "_variables.scss">

* {-webkit-tap-highlight-color:transparent;-webkit-touch-callout: none;}
html 								{position:relative;}
body 								{margin:0;padding:0;width:100%;height:100%;min-width:320px;overflow-x:hidden;overflow-y:scroll;position:relative;background-color:#f5fcff;}
body, table, th, td, p, input, textarea, select {font-family:$primary-font;font-size:$default-font-size;line-height:$default-line-height;color:$default-color;}
p {color:inherit;}
a {color:inherit;font-weight:normal;}
p a {text-decoration:underline;
	&:hover {text-decoration:underline;}
}
a:hover								{text-decoration:none;}
table								{border-collapse:collapse;}
table th 							{text-align:left;}
iframe								{margin:0;padding:0;border:none;}
figure img 							{display:block;}
img									{display:block;max-width:100%;max-height:100%;height:auto;}

dt, dd {
	a:hover {text-decoration:underline;}
}

//'hide classes

// only mobile
.very-mobile {
	display: block; 

	@media screen and (min-width: $phone-upper-boundary){
		display: none;
	}
}

// not mobile
.not-mobile {
	display: none;

	@media screen and (min-width: $phone-upper-boundary) {
    display: block;
}
}

// not until tablet
.not-tablet-p { 
	display: none;

	@media screen and (min-width: 768px) {
    	display: block;
	}
}

.account-page .navigation.not-mobile {
	display: block;
}

.btn.white-space {
	white-space: normal;
}