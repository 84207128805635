/// <reference path = "_variables.scss"

.header {
  padding-top: 20px;
  background: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .2)), url(/gfx/header.jpg) no-repeat center top;

  .logo {
    display: block;
    width: 300px;
    filter: brightness(110%);
    float: left;

    @media screen and (max-width: 1030px) {
      width: 250px;
    }

    @media screen and (max-width: 860px) {
      width: 240px;
    }

    @media screen and (max-width: 768px) {
      width: auto;
    }
  }

  .message {
    padding-top: 60px;
    padding-left: 360px;
    padding-right: 300px;
    font-size: 24px;
    font-weight: bold;
    color: white;
  }

}

.languages {
  float: right;

  li {
    float: left;
    margin-left: 20px;
  }

  a {
    display: block;
    border: 1px solid #fff;
  }

  &-sprite {
    display: block;
    width: 61px;
    height: 34px;
    background: transparent url(/gfx/language-sprite.png) no-repeat left top;
    background-size: 122px 68px;

    &.en {
      background-position: 0 -34px;
    }

    &.nl {
      background-position: -61px -34px;
    }

    &.de {
      background-position: -61px 0;
    }

    &.fr {
      background-position: 0 0;
      transform: rotateY(180deg);
    }
  }
}

//mobile vs desktop

.mobile-menu {
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobile-menu-content {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 40px;
  transition: max-height 0.3s ease;

  &.open {
    height: auto;
    max-height: 1200px;
  }
}

.header .container-fluid.language-menu,
.desktop-menu {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.desktop-menu {
}
