/// <reference path = "_variables.scss"

.block {
    margin-top: 55px;
    background-color: #fff;
    overflow: hidden;

    &.small ~ .small {
        margin-top: 35px;
    }

    &.shadow-outside {
        box-shadow: 10px 30px 70px 0 rgba(#000, .6);
    }

    header {
        padding: 10px 20px;
        position: relative;

        .title {
            display: inline-block;
            color: #fff;
            font-size: 37px;

            &-icon {
                padding-left: 53px;

                .icon {
                    position: absolute;
                    left: 10px;
                    top: 6px;
                }
            }
        }

        .close {
            background: none;
            padding: 0 10px;
            line-height: 1;
            border: none;
            color: #fff;
            font-size: 35px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .icon {
            color: #fff;
            display: inline-block;
            font-size: 40px;
            margin-right: 5px;

            &:before {
                margin-left: 0;
            }
        }
    }

    .content {
        padding: 10px 20px;

        p {
            font-size: 18px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.block .content .form-striped p {
    padding-left: 5px;
    padding-right: 5px;
}

.mobile-menu .block.navigation header {
    padding: 5px 10px;

    .title {
        font-size: 24px;
    }
}

.mobile-menu .block.navigation .links a {
    padding: 10px 8px 10px 10px;
}

.block.navigation {
    &.negative-top {
        @include for-size(desktop-up) {
            margin-top: -50px;
        }
    }

    header {
        padding-left: 13px;
        padding-right: 13px;
    }

    .links {
        &-striped {
            li:nth-child(odd) {
                background-color: #e9f7ff;

                a:hover {
                    background-color: darken(#e9f7ff, 3%);
                }
            }
        }

        &-bordered {
            li a,
            li .link {
                border: 2px solid $blue;
                border-width: 1px 0;
                padding-top: 14px;
                padding-bottom: 14px;
            }

            li:last-child a,
            li:last-child .link {
                border-bottom: 0;
                padding-bottom: 15px;
            }

            li:first-child a,
            li:first-child .link {
                border-top: 0;
                padding-top: 15px;
            }
        }

        li {
            &.title {
                a,
                .link {
                    padding-left: 15px;
                    font-family: $heading-font;
                    font-size: 25px;
                    font-weight: normal;
                }
            }

            &.small {
                a,
                .link {
                    text-align: right;
                    color: $blue;
                    text-decoration: underline;
                    font-size: 13px;
                }
            }
        }

        a,
        .link {
            position: relative;
            display: block;
            padding: 15px 8px 15px 70px;
            color: $blue;
            text-transform: uppercase;
            font-weight: 700;

            &.icon-after {
                padding-right: 20px;

                .badge {
                    position: absolute;
                    right: 30px;
                    top: 12px;
                    text-align: right;
                    font-weight: normal;
                }
            }

            &.icon-before {
                padding-left: 107px;

                .badge {
                    position: absolute;
                    left: 66px;
                    top: 12px;
                    font-weight: normal;
                }
            }
        }

        a:hover {
            background-color: #f8fbff;
        }

        .icon {
            position: absolute;
            left: 10px;
            top: 12px;
            color: $red;
            font-size: 26px;

            &-invoice {
                font-size: 23px;
            }

            &-truck {
                font-size: 32px;
                top: 7px;
            }
        }

        .badge {
            color: $red;
            font-size: 22px;
            font-family: $heading-font;
        }

        &-red {
            a {
                padding-left: 10px;
            }

            a,
            .link {
                color: $red;
            }

            .badge {
                color: $blue;
            }
        }
    }
}

/* Short links */
.block.short-links {
    padding: .433em .7em .867em;
    font-size: 20px;

    h5,
    .title {
        display: block;
        padding-bottom: .833em;
        font-size: 1.3em;
    }

    .links {
        li {
            font-size: .833em;

            + li {
                padding-top: .667em;
            }
        }

        a,
        .bullet {
            display: block;
            position: relative;
            padding-left: .9em;

            &:before {
                position: absolute;
                left: -0.167em;
                top: .233em;
                font-family: Fontello;
                content: '\e802';
                color: $red;
                font-size: .6em;
            }
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* Small - Same as original with half sizes */
.block.short-links.small {
    font-size: 20px;
    @extend .block.short-links;
}

/* BVA */
.block.bva {
    padding: 14px 20px 18px;
    border: 2px solid $blue;
//    border-bottom: 0;
//    border-bottom-left-radius: 0;

    .logo {
        max-width: 255px;
        margin-bottom: 30px;
        width: 100%;
    }

    .title {
        font-size: 30px;
        padding-right: 40px;
        line-height: .9;
    }

    + footer {
        background-color: #fff;

        .goto {
            display: block;
            position: relative;
            padding: 20px 130px 20px 20px;
            font-size: 24px;
            font-family: $heading-font;
            border: 2px solid $red;
            border-radius: 0 0 0 $border-half-radius;

            &:after {
                content: '';
                position: absolute;
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                @include triangle(44px 15px, $blue, down);
            }
        }
    }

    @media screen and (max-width: 980px) {
        .title {
            font-size: 40px;
        }

        + footer {
            .goto {
                font-size: 30px;
                padding-right: 100px;

                &:after {
                    right: 14px;
                    @include triangle(70px 28px, $blue, down);
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .title {
            font-size: 32px;
            line-height: 1;
        }

        + footer {
            .goto.text-red {
                font-size: 24px;
            }
        }
    }
}

/* Customer service */
.block.customerservice {
    padding: 10px 0 0;
    font-family: $heading-font;
    overflow: hidden;
    font-size: 30px; /* Added overflow because of blue footer links*/

    .title {
        font-size: 1.4em;

        @media screen and (min-width: 600px) {
            font-size: 2em;
        }
    }

    .cta {
        margin-top: .833em;

        dt {
            font-size: 1em;
            float: none;

            @media screen and (min-width: 600px) {
                font-size: 1.33em;
            }

            ~ dt {
                margin-top: 1em;
            }
        }

        dd {
            font-size: .9em;
            float: none;
            position: relative;
            padding-left: 1.167em;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: .333em;
                top: .1em;
                @include triangle(0.767em 0.367em, $blue, right);
            }
        }
    }

    section {
        padding: 0 .667em 1em;
        background: transparent url(/gfx/large-icons/person.png) no-repeat right 10px bottom at-2x;
        background-size: 171px 186px;
    }

    footer {
        a {
            display: block;
            padding: .333em .333em .333em 1.833em;
            position: relative;
            background-color: $blue;
            color: #fff;
            font-size: .9em;
            transition: .1s background-color ease-in-out;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 1em;
                top: .433em;
                @include triangle(0.767em 0.367em, #FFF, right);
            }

            &:hover {
                background-color: darken($blue, 5%);
            }

            + a {
                border-top: 2px solid #fff;
            }
        }
    }
}

/* Small - Same as original with half sizes */
.block.customerservice.small {
    font-size: 18px;

    section {
        background: none;
    }
    @extend .block.customerservice;
}

/* Quote */
.block.quote {
    overflow: hidden; /* Added overflow because of background-colors inside of block */
    section {
        padding: 16px 20px;
    }

    ol {
        padding-left: 20px;
        font-size: 18px;
    }

    &.separate-block ~ &.separate-block {
        margin-top: 55px;
    }

    &-overview:not(.separate-block) {
        border-bottom-left-radius: 0;
    }

    &-insert:not(.separate-block) {
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-width: 0;
    }

    .quote {
        &-list {
            width: 100%;
            font-family: $secondary-font;

            tr {
                td {
                    padding: 0;
                    border-top: 0 solid $red;
                    white-space: nowrap;
                    font-size: 14px;
                    font-family: $secondary-font;

                    @media screen and (min-width: $phone-upper-boundary) {
                        border-top: 0 solid $red;
                    }

                    &:first-child {
                        min-width: 135px;
                        padding: 10px 10px 10px 20px;
                        font-family: $secondary-font;
                    }

                    &.btn-row {
                        padding: 0 10px 0 30px;
                        text-align: right;
                    }

                    &.price {
                        padding: 0 20px;
                    }
                }
            }

            .column-2 {
                width: 150px;
            }

            .column-3 {
                width: 1%;
            }

            figure {
                position: relative;
                width: 150px;
                height: 106px;
                background: transparent no-repeat left top;
                background-size: cover;
            }
        }

        &-new {
            width: 100%;

            thead {
                th {
                    padding: 10px 5px;
                    background-color: $blue-light;
                    font-family: $heading-font;
                    color: $red;
                    text-transform: uppercase;
                    font-size: 25px;
                    font-weight: normal;

                    &:first-child {
                        padding-left: 20px;
                    }
                }
            }

            td {
                &:first-child {
                    padding-left: 20px;
                }
            }

            select {
                width: 100%;
            }
        }
    }

    .remove {
        position: relative;
        padding-left: 32px;
        background-color: transparent;
        border: none;
        font-size: 15px;
        color: lighten($default-color, 40%);
        text-transform: uppercase;
        font-family: $secondary-font;
        transition: .2s color ease-in-out;

        &:hover {
            color: $default-color;

            .icon-cancel {
                background-color: darken($red, 10%);
            }
        }

        .icon-cancel {
            position: absolute;
            left: 0;
            top: 5px;
            display: block;
            width: 23px;
            height: 23px;
            line-height: 23px;
            text-align: center;
            background-color: $red;
            border-radius: 0 7px;
            color: #fff;
            font-size: 20px;
            transition: .1s background-color ease-in-out;

            &:before {
                margin: 0;
            }
        }
    }
}

#kavel {
    border-top: 2px solid #f7323f;
}

/* Review */
.block.review {
    border: 2px solid $blue;
    border-bottom-left-radius: 0;
    font-size: 30px;

    section {
        padding: .333em .667em 1.167em;
        background: transparent url(/gfx/large-icons/rating.png) no-repeat right 10px bottom at-2x;
        background-size: 5.267em 3.9em;
    }

    .title {
        font-size: 1.333em;
        line-height: 1;

        @media screen and (min-width: 600px) {
            font-size: 2em;
        }

        &-medium {
            font-size: 1.333em;
            padding: .367em 0 .133em;

            @media screen and (min-width: 600px) {
                font-size: 1.5;
            }
        }

        &-small {
            font-size: .9em;
        }
    }

    + footer {
        padding: .333em 0;
        background-color: $red;
        border: 2px solid #fff;
        border-top: 0;
        border-bottom-left-radius: $border-half-radius;
        font-size: 27px;
        color: #fff;
        font-family: $heading-font;
        text-transform: uppercase;
        text-align: center;
    }
}

/* Small - Same as original with half sizes */
.block.review.small {
    font-size: 16px;

    + footer {
        font-size: 13px;
    }
    @extend .block.review;
}

/* Image */
.block.image {
    .border {
        border: 2px solid #fff;
    }
}

/* Article */
.block.article {
    background-color: transparent;
    padding-left: 30px;

    .background-blue & {
        color: #fff;
    }

    .title {
        font-size: 28px;
        position: relative;
        margin-bottom: 26px;
        margin-top: 47px;

        &:after {
            content: '\e802';
            font-family: Fontello;
            position: absolute;
            left: -30px;
            top: 5px;
            color: $red;
            font-size: 18px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

/* Breadcrumb */
.block.breadcrumb {
    background-color: transparent;
    margin-top: 20px;

    .breadcrumb {
        padding: 0;
        @include clearfix();

        li {
            padding-left: 11px;
            margin-right: 7px;
            position: relative;
            list-style: none;
            float: left;
            text-transform: uppercase;
            color: $blue;
            font-weight: 700;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 2px;
                @include triangle(13px 7px, $blue, right);
            }

            a {
                font-weight: 700;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.total-price-home {
    background-color: #fff;
    border-left: 2px solid $red;
    border-right: 2px solid $red;

    .col-md-6 {
        color: $blue;
        padding-top: 5px;
        padding-bottom: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: 'Montserrat', Arial;
    }

    .row {
        margin: 0;
    }

    p,
    label {
        padding: 5px;
    }
}

.quote-insert #kavelinput {
    padding: 0;
    padding-left: 10px;
    width: 90%;

    @media screen and (min-width: $phone-upper-boundary) {
        width: auto;
    }
}

.block.quote .title.text-red {
    font-size: 36px;
}

@media screen and (max-width: 600px) {
    .flex-mobile {
        display: flex;
        flex-wrap: wrap;

        &.reverse {
            flex-direction: column-reverse;
        }
    }
} 


.price-right {
    
    margin-right: 20px;
    padding: 0 5px;
    @media screen and (max-width: 768px) {
        float: right;
    }

    &.calculate {
        border-bottom: 3px double black;
    }
}