/// <reference path = "_variables.scss"

h1, .title {font-family:$heading-font;}

.text-blue {color:$blue;}
.text-red {color:$red;}

.beoordeling-p {
    font-size: 18px;
}

.margin {
    &-10 {
        margin-bottom: 10px;
    }
}