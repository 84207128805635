/// <reference path = "_variables.scss"


.container { max-width: 1200px; min-width: none; margin: 0 auto;
	@media screen and (max-width:1220px){
		margin:0 10px;
	}

	&-fluid {max-width:1270px;
		@media screen and (max-width:600px){
			padding-left:5px;padding-right:5px;
		}
		@media screen and (min-width:601px){
			padding-left:20px;padding-right:20px;
		}
	}
}

.border {
	&-half-radius { border-radius: 0 $border-half-radius; }
	&-red {border:2px solid $red;}
	&-blue {border:2px solid $blue;}
}

.divider {
	&-top {position:relative;padding-top:30px;
		&:before {content:'';position:absolute;left:-20px;top:10px;right:-20px;height:2px;background-color:$red;}
	}
}

.padding-small {margin-left:-15px;margin-right:-15px;
	[class*=col] {padding-left:15px;padding-right:15px;}
}

.background {
	&-blue { background-color: $blue; }
	&-red { background-color: $red; }
}

.shadow-inner {box-shadow:inset 0 50px 100px -50px rgba(#000, 0.6), inset 0 -50px 100px -50px rgba(#000, 0.6);}


.spacing {
	&-inset {padding-top:0;padding-bottom:25px;
	
	@media screen and (min-width: $phone-upper-boundary) {
    	padding-bottom: 80px;
	}
}
}

body .tooltip {
	&-trigger { display: inline-block; margin-left: 10px; width: 21px; height: 21px; line-height: 21px; text-align: center; background-color: $blue; border-radius: 100%; font-size: 13px;
		&:after { content: '\e804'; font-family: Fontello; color: #FFF; }
	}

	&-inner {
		padding:8px;background-color:#FFF;color:$default-color;border:2px solid $red;border-radius:0 $border-half-radius;
	}

	&.top {margin-top:-7px;
		.tooltip-arrow {
			border:0;width:20px;height:10px;bottom:-5px;margin-left:-10px;

			&:after, &:before {content:'';display:block;position:absolute;left:0;top:0;@include triangle(20px 10px, $red, down);}
			&:after {border-top-color:#FFF;top:-3px;}
		}
	}
} 
 
