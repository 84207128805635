/// <reference path = "_variables.scss"
/// <reference path = "../vendor/bourbon/bourbon.scss"

nav.main { margin-top: 20px; color: #FFF; padding-bottom: 20px;
	.bar {
		&-top {
			border-top: 2px solid #FFF;
			background-color: $red-transparent;
			min-height: 72px;
			@include clearfix();
		}

		&-center {
			background-color: $blue-transparent;
			border-top: 2px solid #FFF;

			.nav { display: table; table-layout: fixed; width: 100%;
				li { display: table-cell; width: 100%; border-left: 2px solid #FFF;
					&.search { width: 240px; border-right: 2px solid #FFF; }
				}
				a {  display: block; height: 46px; padding: 2px 3px; font-family: $heading-font; font-size: 19px; transition: .1s background-color ease-in-out;
					&:hover { background-color: $blue; }
				}
			}

			.search { vertical-align: top; padding: 5px 7px; position: relative;
				input {
					width: 100%;
					padding: 0 35px 0 7px;
					height: 35px;
					border: 2px solid #0050ab;
					font-size: 20px;
					@include placeholder { color: #929292; }
				}
				.search { text-indent: -9999px; padding: 0; background-color: transparent; position: absolute; right: 10px; top: 7px; color: $blue; font-size: 22px; }
				.icon { text-indent: 0; display: block; }
			}

			@media screen and (max-width:1000px) {
				.nav {
					a { font-size: 15px; }
					li.search { width: 200px; }
				}
			}
		}

		&-bottom { height: 33px; background-color: $red-transparent; border: 2px solid #FFF; border-width: 2px 0; }
	}

	.side-menu {
		float: right;
		font-family: $heading-font;
		font-size: 19px;
		li { border: 2px solid #FFF; border-bottom: 0; width: 240px;

			a {  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; display: block; min-height: 34px; padding: 2px 0 0 8px; position: relative; transition: .1s background-color ease-in-out;
				&:hover { background-color: $red; }

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 7px;
					display: block;
					@include triangle(10px 4px, #FFF, right);
				}
			}
		} 

		.title { padding-left: 0; height: 50px; margin-top: -50px; font-size: 30px; background-color: $red-transparent; border-bottom: 2px solid #FFF;
            a { height: 100%;padding: 0;padding-left: 8px;

				&:after {
					display:none;
				} 
			} 
			+ li { border-top: 0; }
		}
		/* Just in case there is no title element */
		li:not(.title):first-child { border-top: 0; }

		@media screen and (max-width:1000px) {
			li { width: 200px; } 
		} 
	} 
}

.pagination > li > a{
	color: #0055b8;
	font-family: "Montserrat";
} 