.dataTables_length {
	display: none;
}

th {
	.tooltip-trigger {
		margin-left: 0px;
	}
}

.dataTable.collapsed {
	.odd.parent .sorting_1 {
		padding-bottom: 1em;
    	padding: 10px 0 10px 45px;
	}	

	.child .dtr-details {

	}

	.dtr-title {
		label {
			padding-left: 0;
			padding-bottom: 5px !important;
		}
	}

	.btn.btn-default.small {
		margin-left: -10px;
	}
}

.dataTable tr td {
	padding: 10px 10px; 

	a {
		text-decoration: underline;
		color: lighten(#f7323f, 10%);

		.glyphicon {
			font-size: 17px;

			&:hover {
				text-decoration: underline;
			}
		}

		.glyphicon-ok {
			color: #26A65B;
		}
	}

	a + a {
		margin-left: 10px;
		
	}
}

.dataTables_wrapper {
	.row .col-sm-12 {
		padding: 0;
	}
}