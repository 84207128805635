/// <reference path = "_variables.scss">

.block {
  .slide-out {
    float: right;
    width: 45px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;

    &:after {
      content: "";
      margin-top: 0;
      display: block;
      border-bottom: 20px solid transparent;
      border-left: 22px solid #0055b8;
      border-top: 20px solid transparent;
      transform: rotate(90deg);
    }

    &.opened {
      &:after {
        transform: rotate(-90deg);
      }
    }

    .text-closed, 
    .text-opened {
      font-size: 12px;
      color: #fff;
      text-transform: uppercase; 
    }

    .text-closed {
    display: none;
}

.text-opened {
    display: block;
}

    &.closed {
      .text-closed {
        display: block;
      }
      .text-opened {
        display: none;
      }
    }
  }

  .slide-content {
    height: auto;
    overflow: hidden;
    transition: height 300ms ease-in;

    &.closed {
      height: 0;
      padding: 0;
    }
  }
}
