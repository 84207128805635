table.dataTable thead th, table.dataTable thead td {
    padding: 10px 20px 10px 4px;
    border-bottom: 0px;
    background: #fff;
}



table.dataTable {
    thead {
        .sorting {
            background-image: url("/gfx/datatables/sort_both.png");
        }

        .sorting_asc {
            background-image: url("/gfx/datatables/sort_asc.png");
        }

        .sorting_desc {
            background-image: url("/gfx/datatables/sort_desc.png");
        }

        .sorting_asc_disabled {
            background-image: url("/gfx/datatables/sort_asc_disabled.png");
        }

        .sorting_desc_disabled {
            background-image: url("/gfx/datatables/sort_desc_disabled.png");
        }
    }

    tbody {
        td {
            color: $blue;
            font-family: $secondary-font;
            font-size: 14px;

            &:first-child {
                padding-left: 10px;
            }
        }
    }

    &.no-footer {
        border-bottom: 0 none;
    }
}

.dataTables_wrapper .dataTables_info {
    color: $blue;
    font-family: $secondary-font;
    font-size: 14px;
    padding-bottom: 0.755em;
}
