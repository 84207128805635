/// <reference path = "_variables.scss"
.form .collapsed.dataTable {
    padding: 0;
}

input.form-control,
select,
.dataTables_wrapper .dataTables_filter input {
    border: 0;
    padding: 5px;
    height: 30px;
    font-size: 14px;
    color: $default-color;
    font-family: $secondary-font;
    border-radius: 0;
    padding-left: 10px;
    @include placeholder {
        text-transform: uppercase;
        color: $light-grey;
        opacity: 1;
        text-transform: initial;
        font-size: .9rem;
    }

    .error & {
        box-shadow: inset 0 0 0 1px $red;
    }

    &[disabled] {
        /*opacity:.7;*/
        cursor: not-allowed;
        display: inline-block;
        padding-top: 16px;
        padding-left: 5px;
        font-size: 14px;
        color: $blue;
        text-transform: uppercase;
        font-family: $secondary-font;
    }

    &[readonly] {
        /*opacity:.7;*/
        cursor: not-allowed;
        display: inline-block;
        padding-top: 16px;
        padding-left: 5px;
        font-size: 14px;
        color: $blue;
        text-transform: uppercase;
        font-family: $secondary-font;
    }
}

textarea.form-control {
    background-color: transparent;
    padding: 5px;
    font-size: 14px;
    color: $default-color;
    font-family: $secondary-font;
    -webkit-appearance: none;
    border-radius: 0;
    height: 100px;
    width: 100%;
    margin: 8px 0 4px;
    border: none;
    padding-left: 10px;
    @include placeholder {
        text-transform: capitalize;
        color: $light-grey;
        font-size: .9rem;
    }

    .error & {
        box-shadow: inset 0 0 0 1px $red;
    }

    &[disabled] {
        /*opacity:.7;*/
        cursor: not-allowed;
        display: inline-block;
        padding-top: 16px;
        padding-left: 5px;
        font-size: 14px;
        color: $blue;
        text-transform: uppercase;
        font-family: $secondary-font;
    }
}

.form {
    // &-striped {
    //     .form-group:nth-of-type(odd), .row:nth-of-type(odd), tr:nth-of-type(odd) {
    //         background-color: #f8fbff;
    //     }
    // }

    .row {
        margin-left: 0;
        margin-right: 0;

        table {
            margin: 12px 0;
        }
    }

    @media screen and (min-width: 600px) {
        [class*="col"] {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &-group {
        padding: 0 20px;
    }

    input.form-control,
    select {
        width: 100%;
        height: 46px;
        margin: 2px 0;
        background-color: transparent;

        &[name="issuer"] {
            border: 1px solid $blue;
        }
    }

    h6 {
        padding-bottom: 15px;
        padding-left: 20px;
        color: $red;
        font-size: 25px;
    }

    label {
        display: inline-block;
        padding-top: 16px;
        padding-left: 5px;
        font-size: 14px;
        color: $blue;
        text-transform: uppercase;
        font-family: $secondary-font;

        &:only-child {
            max-width: 100%;
            overflow-wrap: break-word;
            padding-bottom: 14px;
        }
    }

    .checkbox,
    .radio {
        padding: 15px 0 14px;

        label {
            position: relative;
            padding-top: 0;
            padding-left: 45px;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                left: 5px;
                top: -4px;
                width: 26px;
                height: 26px;
                border: 2px solid $blue;
                border-radius: 3px;
            }

            &:after {
                display: none;
                content: '\e803';
                font-family: Fontello;
                position: absolute;
                left: 8px;
                top: 1px;
                line-height: 1;
                font-size: 20px;
            }
        }

        input {
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0;

            &:checked + label:after {
                display: block;
            }
        }

        .tooltip-trigger {
            margin-left: 20px;
        }

        &.disabled {
            &,
            & label {
                cursor: not-allowed;
            }
        }

        &.error {
            label:before {
                border-color: $red;
            }
        }
    }

    .radio {
        label {
            padding-left: 38px;

            &:before {
                top: 0;
                width: 18px;
                height: 18px;
                border: 3px solid #e4e4e4;
                box-shadow: 0 0 0 3px #fff;
                border-radius: 0 5px;
            }

            &:after {
                content: '';
                left: 10px;
                top: 5px;
                width: 8px;
                height: 8px;
                background-color: $blue;
                border-radius: 0 2px;
            }
        }
    }

    .company-row.inactive {
        display: none;
    }
}

.rating-container .filled-stars {
    top: -2px;
}

.glyphicon {
    left: 2px;
}

label.error {
    padding-top: 5px;
    padding-bottom: 10px;
    color: $red !important;
}

label.error:before {
    display: none;
}

label.error-kavel {
    padding-left: 10px;
    border: 1px solid;
    margin-top: 5px;
    padding-bottom: 5px;
    display: block;
    width: 90%;
    font-size: 16px;
}

.file-upload {
    margin-bottom: 20px;
    float: none !important;

    input {
        border: 1px solid $blue;
        padding: 10px;
        margin-top: 15px;
        display: block;
    }

    h6, .upload-p {
        padding-left: 5px;
    }
}

.dataTable tbody .even {
    background-color: #f8fbff;
}
// new form

.striped {
    background-color: #f8fbff;
}

.white-row {
    background-color: #fff;
}

.block section.content {
    padding: 10px 0;

    .form-striped {
        // .form-group:nth-of-type(odd),
        .striped {
            background-color: #f8fbff;
        }

        .white-row {
    background-color: #fff;
}

        .row {
            margin: 5px 0;
            padding: 10px 0;

            div + div {
                label {
                    color: #4e4e4d;
                }

                > label {
                    color: #0055b8;
                }

                th label {
                    color: #0055b8;
                }
                
            }
        }

        label {
            padding-top: 0;
            padding-bottom: 5px;

            @media screen and (max-width: 768px) {
                padding-top: 5px;
                padding-bottom: 0;
            }
        }

        .radio {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .col-xs-12 + .col-xs-12 {
        @media screen and (max-width: 768px) {
            margin-top: 5px;
        }
    }

    .col-xs-12 + .col-xs-12 .radio {
        @media screen and (max-width: 768px) {
            margin-top: 5px;
        }
    }
}

// smaller margins for verwerking factuur
.block .factuur-verwerking {
    .row {

        > .col-xs-12:first-child {
            label {
                color: #4e4e4d;
            }
        }
        .not-tablet-p {
            margin-top: 0;  
        }
    }

    h6 {
            padding-left: 5px;
        
    }

}

#cloneInput {
    border: 1px solid red;
    margin: 0;
    padding: 5px 10px 3px 10px;
}

// new input
.form input.form-control,
input.form-control {
    border: 1px solid #d7d7d7;
    height: 35px;
    margin-top: 5px;
}
