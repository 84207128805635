@media screen and (max-width: 600px) {
.block.quote .quote-list .btn-row {
    clear: left;
    width: 100%;
    background-color: #f2f2f2;
    text-align: right;
}
.block.quote .quote-list figure {
    width: auto;
}
.block.quote .quote-list figure {
    position: relative;
    flex-grow: 1;
    background: left top no-repeat;
    background-size: contain;
  height: auto;
    &::after {
      content: "";
      display: block;
      padding-bottom: 76%;
    }
}
}

@media screen and (max-width: 768px) {


// quote-home 

.block.quote .quote-list {
    width: 100%;
    font-family: Montserrat, Arial;
}

.block.quote .quote-list .row {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.block.quote .quote-list .row::after {
    clear: both;
    content: '';
    display: table;
}

.block.quote .quote-list .row + .row {
    border-top: 2px solid #f7323f;
}

.block.quote .quote-list article {
    width: 70%;
    float: left;
    padding-right: 10px;
}

.block.quote .quote-list article table {
    width: 100%;
}

.block.quote .quote-list article td {
    padding: 5px 6px;
    text-transform: uppercase;
    font-family: Montserrat, Arial;
}

.block.quote .quote-list article tr:nth-child(even) td {
    background-color: #f2f2f2;
}



.block.quote .quote-new,
.block.quote .quote-new select {
    width: 100%;
}



.block.quote .quote-new thead th {
    padding: 10px 5px;
    background-color: #e9f4f9;
    font-family: 'Bebas Neue', Arial;
    color: #f7323f;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 400;
}

.block.quote .quote-new td:first-child,
.block.quote .quote-new thead th:first-child {
    padding-left: 20px;
}

.block.quote .remove {
    position: relative;
    padding-left: 26px;
    background-color: transparent;
    border: none;
    color: #0055b8;
    text-transform: uppercase;
    font-family: Montserrat, Arial;
}

.block.quote .remove .icon-cancel {
    position: absolute;
    left: 0;
    top: 6px;
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background-color: #f7323f;
    border-radius: 0 4px;
    color: #fff;
    font-size: 16px;
    transition: .1s background-color ease-in-out;
}

.block.quote .remove .icon-cancel:before {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}


// datepicker 

/*!
 * Datepicker for Bootstrap v1.6.0-alpha (https://github.com/eternicode/bootstrap-datepicker)
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px; 
} 

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: none;
}

.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover:active {
  background-color: #fbf069\9;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
  background: #eee;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border-radius: 0;
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected.disabled:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected[disabled] {
  background-color: grey;
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover:active {
  background-color: #666\9;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active {
  background-color: #039\9;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
  background-color: #039\9;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange .add-on {
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

.datepicker-dropdown:after,
.datepicker-dropdown:before {
  display: inline-block;
  border-top: 0;
  position: absolute;
  content: "";
} /*!
 * Datepicker for Bootstrap v1.6.0-alpha (https://github.com/eternicode/bootstrap-datepicker)
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px;
}

.datepicker-dropdown:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.15);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.datepicker-dropdown:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid rgba(0, 0, 0, 0.15);
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker > div {
  display: none;
}

.datepicker table {
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #777;
  cursor: default;
}

.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0;
}

.datepicker table tr td.highlighted.focus,
.datepicker table tr td.highlighted:focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2;
}

.datepicker table tr td.highlighted.active,
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}

.datepicker table tr td.highlighted.active.focus,
.datepicker table tr td.highlighted.active:focus,
.datepicker table tr td.highlighted.active:hover,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted:active:hover {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2;
}

.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled].focus,
.datepicker table tr td.highlighted[disabled]:focus,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted.focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
fieldset[disabled] .datepicker table tr td.highlighted:hover {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td.highlighted.focused {
  background: #afd9ee;
}

.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #777;
}

.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today.focus,
.datepicker table tr td.today:focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400;
}

.datepicker table tr td.today.active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}

.datepicker table tr td.today.active.focus,
.datepicker table tr td.today.active:focus,
.datepicker table tr td.today.active:hover,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today:active:hover {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400;
}

.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled].focus,
.datepicker table tr td.today[disabled]:focus,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today.focus,
fieldset[disabled] .datepicker table tr td.today:focus,
fieldset[disabled] .datepicker table tr td.today:hover {
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today.focused {
  background: #ffc966;
}

.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #777;
}

.datepicker table tr td.range {
  color: #000;
  background-color: #eee;
  border-color: #bbb;
  border-radius: 0;
}

.datepicker table tr td.range.focus,
.datepicker table tr td.range:focus {
  color: #000;
  background-color: #d5d5d5;
  border-color: #7c7c7c;
}

.datepicker table tr td.range.active,
.datepicker table tr td.range:active,
.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d5d5d5;
  border-color: #9d9d9d;
}

.datepicker table tr td.range.active.focus,
.datepicker table tr td.range.active:focus,
.datepicker table tr td.range.active:hover,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range:active:hover {
  color: #000;
  background-color: #c3c3c3;
  border-color: #7c7c7c;
}

.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled].focus,
.datepicker table tr td.range[disabled]:focus,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.focus,
fieldset[disabled] .datepicker table tr td.range:focus,
fieldset[disabled] .datepicker table tr td.range:hover {
  background-color: #eee;
  border-color: #bbb;
}

.datepicker table tr td.range.focused {
  background: #d5d5d5;
}

.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #eee;
  color: #777;
}

.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted.focus,
.datepicker table tr td.range.highlighted:focus {
  color: #000;
  background-color: #c1d7e3;
  border-color: #4b88a6;
}

.datepicker table tr td.range.highlighted.active,
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted:hover {
  color: #000;
  background-color: #c1d7e3;
  border-color: #73a6c0;
}

.datepicker table tr td.range.highlighted.active.focus,
.datepicker table tr td.range.highlighted.active:focus,
.datepicker table tr td.range.highlighted.active:hover,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted:active:hover {
  color: #000;
  background-color: #a8c8d8;
  border-color: #4b88a6;
}

.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled].focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted.focused {
  background: #c1d7e3;
}

.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e4eef3;
  color: #777;
}

.datepicker table tr td.range.today {
  color: #000;
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today.focus,
.datepicker table tr td.range.today:focus {
  color: #000;
  background-color: #f4b747;
  border-color: #815608;
}

.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover {
  color: #000;
  background-color: #f4b747;
  border-color: #bf800c;
}

.datepicker table tr td.range.today.active.focus,
.datepicker table tr td.range.today.active:focus,
.datepicker table tr td.range.today.active:hover,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today:active:hover {
  color: #000;
  background-color: #f2aa25;
  border-color: #815608;
}

.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled].focus,
.datepicker table tr td.range.today[disabled]:focus,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today.focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
fieldset[disabled] .datepicker table tr td.range.today:hover {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #f7ca77;
  color: #777;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #777;
  border-color: #555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected:focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #161616;
}

.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #373737;
}

.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active.focus,
.datepicker table tr td.selected.highlighted.active:focus,
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected:active:hover {
  color: #fff;
  background-color: #4c4c4c;
  border-color: #161616;
}

.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected.highlighted[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:hover,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected:hover {
  background-color: #777;
  border-color: #555;
}

.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active.highlighted[disabled].focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:hover,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #777;
  cursor: default;
}

.btn,
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th,
.input-group.date .input-group-addon {
  cursor: pointer;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover.focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active:hover:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover.active.focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.datepicker table tr td span.active.disabled:hover.active:hover,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active:hover:active:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #777;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-daterange {
  width: 100%;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.42857143;
  text-shadow: 0 1px 0 #fff;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}

.dropdown-menu {
  border: 1px solid #f7323f;
  border-radius: 0 6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top-color: #f7323f;
}

.datepicker table tr td.today {
  background: rgba(247, 50, 63, 0.2);
}

.datepicker table tr td.today:hover:hover {
  background: rgba(247, 50, 63, 0.3);
}

.datepicker table tr td.active.active {
  background: #0055b8;
  color: #fff;
}

.datepicker table tr td.active.active:hover:hover {
  background: #00499f;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #bbb;
}


// random


.header .btn-text,
nav.main .bar-center .nav a,
nav.main .side-menu {
  font-family: "Bebas Neue", Arial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



//header-mobile

.header {
  padding: 10px 5px;
  background: url(/gfx/header.jpg) center bottom no-repeat;
  background-size: cover;
}

.header .container-fluid {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  
}

.header .logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 7px;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .logo img {
  min-width: 100px;
  max-width: 130px;
}

.header .logo-simple img {
  width: 42px;
  height: 100%;
}

.header .logo-simple .title {
  display: block;
  padding: 0 5px;
  color: #f7323f;
}

.header .btn {
  color: #fff;
  border-radius: 0 7px;
  border: 1px solid #fff;
}

.header .btn-left {
  float: left;
}

.header .btn-right {
  float: right;
}

.header .mijn-account {
  font-size: 12px;
}

.header .mijn-account-out {
  font-size: 16px;
  line-height: 38px;
}

.header .btn-blue {
  background-color: rgba(0, 85, 184, 0.8); 
}

.header .btn-red {
  background-color: rgba(247, 50, 63, 0.8);
  height: 40px;
  text-align: left;
  line-height: 1;
}

.header .btn-icon {
  padding: 0;
}

.header .btn-text {
  font-size: 17px;
  padding: 0 10px;
}

.header .btn-clear {
  border-color: #0055b8;
  color: #0055b8;
}

.header .icon {
  display: block;
  position: relative;
}

.header .icon-menu {
  width: 38px;
  height: 38px;
  position: relative;
}

.header .icon-menu .lines:after,
.header .icon-menu .lines:before,
.header .icon-menu:after {
  content: "";
  position: absolute;
  left: 7px;
  right: 7px;
  height: 2px;
  background-color: #fff;
}

.header .icon-menu:after {
  top: 50%;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.header .icon-menu .lines:after,
.header .icon-menu .lines:before {
  top: 25%;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.header .icon-menu .lines:after {
  top: 75%;
}




// nav-mobile not needed

nav.main {
  margin-top: 20px;
  color: #fff;
  padding-bottom: 20px;
}

nav.main .bar-top {
  border-top: 2px solid #fff;
  background-color: rgba(247, 50, 63, 0.8);
  min-height: 72px;
}

nav.main .bar-top::after {
  clear: both;
  content: "";
  display: table;
}

nav.main .bar-center {
  background-color: rgba(0, 85, 184, 0.8);
  border-top: 2px solid #fff;
}

nav.main .bar-center .nav {
  display: table;
  table-layout: fixed;
  width: 100%;
}

nav.main .bar-center .nav li {
  display: table-cell;
  width: 100%;
  border-left: 2px solid #fff;
}

nav.main .bar-center .nav li.search {
  width: 240px;
  border-right: 2px solid #fff;
}

nav.main .bar-center .nav a {
  display: block;
  height: 46px;
  padding: 2px 3px;
  font-size: 19px;
}

nav.main .bar-center .nav a:hover {
  background-color: #0055b8;
}

nav.main .bar-center .search {
  vertical-align: top;
  padding: 5px 7px;
  position: relative;
}

nav.main .bar-center .search input {
  width: 100%;
  padding: 0 35px 0 7px;
  height: 35px;
  border: 2px solid #0050ab;
  font-size: 20px;
}

nav.main .bar-center .search input::-webkit-input-placeholder {
  color: #929292;
}

nav.main .bar-center .search input::-moz-placeholder {
  color: #929292;
}

nav.main .bar-center .search input:-moz-placeholder {
  color: #929292;
}

nav.main .bar-center .search input:-ms-input-placeholder {
  color: #929292;
}

nav.main .bar-center .search .search {
  text-indent: -9999px;
  padding: 0;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 7px;
  color: #0055b8;
  font-size: 22px;
}

nav.main .bar-center .search .icon {
  text-indent: 0;
  display: block;
}

nav.main .bar-bottom {
  height: 33px;
  background-color: rgba(247, 50, 63, 0.8);
  border: 2px solid #fff;
  border-width: 2px 0;
}

nav.main .side-menu {
  float: right;
  font-size: 19px;
}

nav.main .side-menu li {
  border: 2px solid #fff;
  border-bottom: 0;
  width: 240px;
}

nav.main .side-menu li a {
  display: block;
  min-height: 34px;
  padding: 2px 0 0 8px;
  position: relative;
}

nav.main .side-menu li a:hover {
  background-color: #f7323f;
}

nav.main .side-menu li a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  display: block;
  height: 0;
  width: 0;
  border-bottom: 5px solid transparent;
  border-left: 4px solid #fff;
  border-top: 5px solid transparent;
}

nav.main .side-menu .title {
  padding-left: 8px;
  height: 50px;
  margin-top: -50px;
  font-size: 30px;
  background-color: rgba(247, 50, 63, 0.8);
  border-bottom: 2px solid #fff;
}

nav.main .side-menu .title + li,
nav.main .side-menu li:not(.title):first-child {
  border-top: 0;
}

// total price mobile

.total-price-home {
  padding: 0 0 0 20px;
  color: #0055b8;
  font-size: 14px;
  font-family: "Montserrat", Arial;

  .row:last-child {
    margin-bottom: 20px;
  }
} 

.total-price-home label {
  text-transform: uppercase;
}

.total-price-home .col-md-6 {
  padding-top: 5px;
  padding-bottom: 5px;
}


}

// header mq 

@media screen and (min-width: 360px) {
  .header .btn-text {
    padding-right: 20px;
  }

  .header .btn-clear {
    padding-right: 10px;
  }
}

@media screen and (min-width: 400px) {
  .header .btn-text {
    padding-right: 20px;
  }

  .header .btn-clear {
    padding-right: 10px;
  }
}