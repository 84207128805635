$blue: #0055b8;
$blue-transparent: rgba($blue, 0.8);
$blue-light: #e9f4f9;
$red: #f7323f;
$red-transparent: rgba($red, 0.8);
$default-color: #4e4e4d;
$light-grey: #888;

$primary-font: 'Alegreya Sans', Arial;
$secondary-font: 'Montserrat', Arial;
$heading-font: 'Bebas Neue', Arial;

$default-font-size: 15px;
$default-line-height: normal;

$heading-font-size: 40px;
$heading-line-height: normal;

$button-font-size: 25px;

$border-half-radius: 18px;

$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 768px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;

$icon-path: '../images/icons';

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 768px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) { @content; }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) { @content; }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) { @content; }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) { @content; }
  }
}
  
