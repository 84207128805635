/// <reference path = "_variables.scss"

footer.main {
    background: transparent url(/gfx/footer.jpg) no-repeat center bottom;

    .content + & {
        margin-top: 80px;
    }

    blockquote {
        margin-bottom: 40px;
        min-height: 60px;
        padding: 15px 0;
        background-color: $red-transparent;
        border: 2px solid #fff;
        border-width: 2px 0;
        font-size: 30px;
        text-transform: uppercase;
        color: #fff;
        font-family: $heading-font;
        text-align: center;

        .outline {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: -30px;
                bottom: -30px;
                right: 0;
                border: 3px solid $blue;
                border-radius: 30px;
            }
        }
    }

    .contact {
        margin-bottom: 20px;
        margin-top: 70px;
        padding-bottom: 25px;
        text-align: center;
        background-color: $red;

        @media screen and (min-width: 768px) {
            margin-top: 230px;
        }

        .block {
            margin-top: 20px;
            border: 3px solid $blue;
            text-align: left;
            color: #fff;
            padding: 2px 10px 10px;
            background-color: transparent;

            @media screen and (min-width: 768px) {
                margin-top: -45px;
            }
        }

        h6 {
            padding-bottom: 15px;
            color: $blue;
            font-size: 25px;
        }

        dl,
        p {
            font-size: 20px;
        }

        dt {
            width: 180px;
        }
    }

    .link-bar {
        background-color: $blue;
        color: #fff;
        padding: 10px 0;

        .title {
            font-size: 25px;
        }

        ul {
            display: table;
            width: 100%;

            li {
                font-size: 18px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        &-small {
            padding: 20px 0 30px;

            @media screen and (min-width: $phone-upper-boundary) {
                padding: 45px 0 30px;
            }

            ul {
                text-align: center;

                li {
                    display: block;
                    padding: 0 20px;
                    font-size: 17px;
                    font-family: $heading-font;
                    text-transform: uppercase;
                    color: $blue;

                    a:hover {
                        text-decoration: underline;
                    }

                    @media screen and (min-width: $phone-upper-boundary) {
                        display: inline-block;
                    }
                }
            }
        }

        @media screen and (max-width: 1000px) {
            .title {
                font-size: 20px;
            }

            ul li {
                font-size: 13px;
            }
        }
    }
}

footer {
    .btn-row {
        padding: 4px;
        text-align: right;

        @media screen and (max-width: 600px) {
            a + a {
				margin-top: 5px;
				display: inline-block;
            }
        }
    }
}