﻿/// <reference path = "_variables.scss"

.video-container { position: relative; margin-left:auto;margin-right:auto;margin-bottom:30px; max-width:1200px; padding: 0 0 56.25%; height: 0; overflow: hidden;
	iframe, object, embed { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

	.ico.play { position: absolute; left: 50%; top: 50%; transform: translateY(-50%) translateX(-50%); cursor: pointer; }

	&.show-player {
		.preview, .ico { display: none; }
	}
}

.ico.play { display: block; width: 217px; height: 217px; background: transparent url(/gfx/play.png) no-repeat left top at-2x; background-size: 217px 217px; }
