﻿@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue-webfont.eot');
    src: url('../fonts/bebasneue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue-webfont.woff') format('woff'),
         url('../fonts/bebasneue-webfont.ttf') format('truetype'),
         url('../fonts/bebasneue-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;

}